@import "../../assets/styles/colors.scss";

.container {
    display: flex;
    align-items: center;
    margin-top: 100px;

}

.container-card {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 500px;
    height: 100px;
    border: 1px solid $grey300;
    border-radius: 2px;
    margin-bottom: 0.1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: $white-color;
    justify-content: center;
}