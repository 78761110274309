// Login.scss
@import "/src/assets/styles/colors.scss";

.login {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loginLenguaje {
    position: absolute;
    right: 50px;
    top: 20px;
  }
}

.buttonLenguage {
  background-color: transparent;
  padding: 5px;
}

.buttonLenguage:hover {
  background-color: rgba(128, 128, 128, 0.5);
  padding: 5px;
}
