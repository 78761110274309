@import "../../assets/styles/colors.scss";

.container-my-cardTurns {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  width: 98%;
  height: 100px;
  padding: 5px;
  border: none;
  cursor: pointer;
  align-self: center;
  border: 1px solid $grey300;
  border-radius: 2px;
  margin-top: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  justify-content: space-between;
  margin-top: 15px;


  @media only screen and (max-width: 768px) {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &.confirmed {
    background-color: $white-color;

    h3 {
      margin-left: 10px;
      color: green
    }
  }

  &.canceled {
    background-color: $grey100;

    h3 {
      color: red;
      margin-left: 10px;
    }

  }


  &--hovered {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: $grey100;

    &.clickable {
      background-color: $grey100;
    }
  }



  option {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .past {
    background-color: $grey300;
  }
}

.myTurns-state {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    margin: 0;
  }

  h4 {
    align-self: center;
    font-size: 15px;
    margin: 5px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
  }
}


.myTurns-date {
  display: flex;
  flex-direction: column;

  h2 {
    margin-left: 10px;
    font-size: 15px;
    font-size: 20px;
  }

  h4 {
    align-self: center;
    font-size: 17px;
    margin: 5px;
    font-weight: normal;

  }
}

.container-confirm {
  &.confirmed {
    background-color: $white-color;

    .status {
      color: green
    }
  }

  &.canceled {

    .status {
      color: red
    }

  }
}