@import "../../assets/styles/colors.scss";

.container-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
}

.container-icon-text {
  align-items: center;
  font-size: 1.2rem;
  color: #333;
  padding: 10px;

  span {
    display: flex;
    margin-right: 10px;
  }
}

.container-icon-text i {
  font-size: 1.5rem;
  color: #888;
  margin-right: 0.5rem;
}

.title-info {
  font-size: 1.5rem;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 768px) {
    margin-right: 25rem;
    font-size: 1.5rem;
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    margin-right: 2rem;
    margin-left: 0px;
  }
}

.title-info-first {
  @media only screen and (min-width: 768px) {
    margin-right: 25rem;
    font-size: 1.5rem;
    text-align: left;
  }


  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.row-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 85%;


  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-right: 2rem;
    margin-bottom: 0;
    margin-left: 0px;
    width: 100%;
  }
}

.border-bottom {
  border-bottom: 1px solid #ccc;

  @media only screen and (max-width: 768px) {
    border-bottom: none;
  }
}

.span-information {
  margin-left: 1rem;
  width: 500px;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    width: 90%;
  }
}

.button-turn-info {
  width: 250px;
  height: 50px;
  border: none;
  border-color: #e63700;
  background-color: $red-color;
  color: #ffffff;
  font-size: 1rem;
  align-self: center;
  cursor: pointer;

  &:hover {
    background-color: $red-color;
    opacity: 0.7;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 0;
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 768px) {}
}

.column-info {
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-left: -535px;

  @media only screen and (max-width: 768px) {
    margin-right: 2rem;
    margin-bottom: 0;
    margin-left: 40px;
    width: 100%;
  }
}