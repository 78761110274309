// colors.scss
$primary-color: #00234b;
$secondary-color: #4f81bd;
$text-color: #1d1d1b;
$light-gray-color: #e9e9e9;
$dark-gray-color: #4f4f4f;
$dark-gray-light-color: #dfdddd;
$gray-color: #4f4f4f;
$white-color: #ffffff;
$red-color: #FF3D00;
$nav-color: #424242;
$black-color: #000000;
$grey100: #F5F5F5;
$grey300: #E0E0E0;
