@import "../../assets/styles/colors.scss";

.container-myTurns {
  display: flex;
  justify-content: space-between;
  margin: 100px;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    align-items: center;
  }

  .tittle-turn-before {
    margin-bottom: 95px;
    font-weight: bold;
    font-size: 30px;

    @media only screen and (max-width: 768px) {
      margin-bottom: 10px;
      justify-content: center;
    }
  }

  .tittle-turn-after {
    font-weight: bold;
    font-size: 30px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    margin-top: 20px;
  }
}

.turn-next {
  width: 50%;
  padding: 10px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0px;
  }

  p {
    font-size: 20px;
    font: bold;
  }
}

.turn-before {
  width: 50%;
  margin-left: 15px;
  padding: 10px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0px;
  }

  p {
    font-size: 20px;
    font: bold;


  }
}

.button-turn {
  width: 100%;
  height: 50px;
  border: none;
  border-color: #e63700;
  background-color: $red-color;
  color: #ffffff;
  font-size: 1rem;
  align-self: center;
  cursor: pointer;

  &:hover {
    background-color: $red-color;
    opacity: 0.7;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}