.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f44336;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
}
