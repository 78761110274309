// Form.scss
@import "/src/assets/styles/colors.scss";

.form {
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  width: 100%;
  display: block;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .title {
    color: $primary-color;
    font-size: 2rem;
    margin-bottom: 2rem;
    align-self: start;
  }

  .label {
    display: block;
    text-align: left;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: $text-color;
  }

  .input {
    display: block;
    height: 34px;
    margin-top: 5px;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    font-size: 14px;
    background-color: #fff;
    color: #555555;
    
    font-size: 16px;
    transform-origin: top left;
    transform: scale(0.75);
    width:  130%;                   

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $secondary-color;
   
    }
  }

  .button {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-color: #e63700;
    background-color: #FF3D00;
    color: #FFFFFF;
    font-size: 1rem;
    cursor: pointer;
  }

  .second-button {
    width: 100%;
    margin-top: 1rem;
    background-color: #212121;
    border-color: #ccc;
    border: none;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    font-size: 1rem;
    cursor: pointer;
  }
}