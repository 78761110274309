
.card-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  a {
    margin-top: 75px;
    width: 00px;
    margin-bottom: -75px;
    align-items: center;
    text-decoration: none;
    color: black;
  }
}

.card-title {
  font-size: 25px;
  align-self: center;
  margin-top: 50px;
}

.container {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
