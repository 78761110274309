@import "../../assets/styles/colors.scss";

.container-confirm {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    padding: 16px;
    border-radius: 4px;
    border-color: $primary-color;
    border: 1px solid $grey300;
    border-radius: 2px;
    width: 500px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 768px) {
        width: 300px;
        margin-top: 100px;

    }

}


.button-box {
    margin-top: auto;
}

.button-turn-confirm {
    display: flex;
    width: 200px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border: none;
    border-color: #e63700;
    background-color: $red-color;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        background-color: $red-color;
        opacity: 0.7;
    }
}

.datos1 {
    display: flex;
    flex-direction: row;
    width: 500px;
    justify-content: space-between;
    margin-bottom: 10px;

    div {
        width: 50%;
    }

    p {
        margin-bottom: 5px;
        margin: 5px;
        margin-left: 0px;
        font: 100;
    }


    @media only screen and (max-width: 768px) {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

.checkbox-container {
    padding: 1rem;
}

.clickable-word {
    text-decoration: underline;
    cursor: pointer;
}

.button-turn-confirm:disabled {
    background-color: #ccc;
    color: #999;
  }