@import "../../assets/styles/colors.scss";

.container-select {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 500px;
    height: 50px;
    border: 1px solid $grey300;
    border-radius: 2px;
    margin-bottom: 0.1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: $white-color;
    justify-content: space-between;

    &--hovered {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        background-color: $grey100;

        &.clickable {
            background-color: $grey100;
        }

    }

    @media only screen and (max-width: 768px) {
    width: 300px;
    
      }
    p {
        display: flex;
        margin-left: 10px;
        margin-right: 10px;

    }

    .container-option {
        display: flex;
        justify-content: space-between;
    }

    .option-red {
        color: $red-color;
        margin-right: 10px;
        margin-top: 15px;
    }
}