@import "../../assets/styles/colors.scss";

.navigation-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $nav-color;
  color: $white-color;
  height: 50px;
  width: 100%;

  .div-ul {
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;

    &:hover {
      background-color: $black-color;
    }
  }

  .nothover {
    cursor: default;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    &:hover {
      background-color: $nav-color;
    }
  }

  .not {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin-left: 0;
    }

    .name-nohover {
      &:hover {
        background-color: red;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        text-decoration: none;
        color: white;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

.bg-button {
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    padding: 0 20px;

    .ul-solo {
      display: none;
      width: 90%;
      justify-content: space-between;

      &.active {
        display: flex;
        flex-direction: column;
        background-color: black;
        width: 115%;
        margin-top: 50px;
      }
    }
  }

  .div-ul {
    margin-left: 10px;

    &:hover {
      background-color: $black-color;
    }
  }

  ul {
    width: 100%;
    margin-top: 50px;

    &.active {
      align-items: flex-start;
    }
  }

  div.bg-button {
    position: fixed;
    top: 5px;
    right: 20px;
    z-index: 99999;
  }
}

.body {
  margin-top: 50px;

  &.active {
    margin-top: 300px;
  }
}

.yes {
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-left: 0;
  }
}

.containerOptions {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonLenguage {
  cursor: pointer;
}

.space {
  margin-right: 10px;
}
